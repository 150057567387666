import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { auth, db } from "../config/firebaseSetup";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import "../styles/payNow.css";
import Loading from "../components/Loader";
import { FIREBASE_URL } from "../config/urlConfig";

const PayNowPage = () => {
  const { token } = useParams();
  const [tokenData, setTokenData] = useState({});
  const [currentUser, setCurrentUser] = useState(null);
  const [auctionItem, setAuctionItem] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const decodeToken = async () => {
      if (!currentUser) return;

      try {
        const { data } = await axios.post(`${FIREBASE_URL}/verifyToken`, {
          token,
        });

        if (data.userId !== currentUser.uid) {
          toast.error("Login with the correct account to proceed");
          return;
        }

        setTokenData({
          auctionId: data.auctionId,
          userId: data.userId,
          userCommission: data.userCommission,
        });

        if (data.auctionId) {
          const auctionDocRef = doc(db, "Auction", data.auctionId);
          const auctionDoc = await getDoc(auctionDocRef);

          if (auctionDoc.exists()) {
            const item = { id: auctionDoc.id, ...auctionDoc.data() };
            setAuctionItem(item);

            if (item.status.toLowerCase() === "sold") {
              setPaymentSuccess(true);
            }
          } else {
            toast.error("Auction item not found");
          }
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        toast.error("Failed to decode token");
      } finally {
        setLoading(false);
      }
    };

    if (currentUser && token) {
      decodeToken();
    }
  }, [token, currentUser]);

  const handlePayment = async () => {
    if (!tokenData.auctionId || !tokenData.userId) {
      toast.error("Invalid token data");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        "https://us-central1-auction-99162.cloudfunctions.net/processManualPayment",
        { auctionId: tokenData.auctionId, userId: tokenData.userId }
      );

      if (response.status === 200) {
        toast.success("Payment Successful");
        setPaymentSuccess(true);
      } else {
        toast.error("Payment Failed");
        console.error("Error processing payment:", response.data);
      }
    } catch (error) {
      console.error("Error making payment request:", error);
      toast.error("Error processing payment");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (!currentUser) {
    return <h2 className="payNowState">No User Please Login</h2>;
  }

  if (!tokenData.userId) {
    return <h2 className="payNowState">Getting User Data</h2>;
  }

  if (tokenData.userId !== currentUser.uid) {
    return (
      <h2 className="payNowState">Login with the correct account to proceed</h2>
    );
  }

  if (paymentSuccess) {
    return (
      <div className="payNowState">
        <h2>Your Payment Has Been made successfully</h2>
      </div>
    );
  }

  if (!auctionItem) {
    return <h2 className="payNowState">Getting Auction Details</h2>;
  }

  const commission =
    auctionItem.highestBid * (parseFloat(tokenData.userCommission) / 100);
  const totalAmount =
    parseInt(auctionItem.highestBid) * 100 + parseInt(commission) * 100;

  return (
    <div className="pay-now-page">
      <div className="payNowContainer">
        <div className="auction-details">
          <h2>Auction Winner</h2>
          <p>
            Congratulations! You were the highest bidder on the auction item.
          </p>
        </div>
        <div className="wonItemImg">
          <img src={auctionItem?.images[0]?.fileimage} alt="" />
        </div>
        <div className="">
          <div className="auction-details">
            <span>Email:</span>
            <span>{auctionItem.lastBidderEmail}</span>
          </div>

          <div className="auction-details">
            <span>Auction Title:</span>
            <span>{auctionItem.auctionTitle}</span>
          </div>

          <div className="auction-details">
            <span>Highest Bid:</span>
            <span>${auctionItem.highestBid}</span>
          </div>

          <div className="auction-details">
            <span>Your Bid:</span>
            <span>${auctionItem.highestBid}</span>
          </div>

          <div className="auction-details">
            <span>Total Pay:</span>
            <span>
              ${totalAmount / 100} ({tokenData.userCommission} % of Commission)
            </span>
          </div>
        </div>
        <button className="pay-now-button" onClick={handlePayment}>
          Pay Now
        </button>
      </div>
    </div>
  );
};

export default PayNowPage;
